<template>
  <!-- 学员管理页面-->
  <div class="class-student">
    <div class="class-student-top f-c">
      <div class="f-l class-student-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="class-student-tool-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l class-student-top-text">
        {{ $t("el.studentManage.studentMag1") }} {{ $route.query.name }}
        {{ $t("el.studentManage.studentMag2") }}
      </div>
    </div>
    <div class="right-list">
      <xm-table
        ref="xmTable"
        :fatch-method="fatchMethod"
        :autoLoad="false"
        :form-items="formItems"
        :columns="columns"
        :formOptions="{
          hiddenDefaultFormBtn: true,
        }"
        :tableOptions="{
          height: tableHeight,
        }"
      >
        <template slot="formBtn">
          <el-button
            type="primary"
            size="small"
            class="teachManage-tool-btnicon"
            @click="openStudentDialog"
            >{{ $t("el.courseClassManage.addStudent") }}</el-button
          >
          <el-upload
            class="upload-demo"
            :action="baseUrl + '/schoolStudent/import'"
            multiple
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :headers="myHeaders"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
          >
            <el-button type="primary" plain size="small">{{
              $t("el.common.import")
            }}</el-button>
          </el-upload>

          <a
            class="custom-button"
            target="_blank"
            :href="baseUrl + '/model/modelDownload?type=xpk_student'"
          >
            <el-button
              type="primary"
              plain
              size="small"
              class="teachManage-tool-btnicon"
            >
              <span class="button-text">{{
                $t("el.infrastructure.downloadTemplate")
              }}</span>
            </el-button>
          </a>
        </template>

        <template slot="alredySigned" slot-scope="scope">
          <span
            class="s-span"
            :class="{ sclick: ~~scope.row.reportedCourseCount > 0 }"
            @click="toReported(scope.row)"
            >{{ scope.row.reportedCourseCount }}</span
          >
        </template>

        <template slot="otherHandle" slot-scope="scope">
          <el-button type="text" @click="updateAttendClass(scope.row)">{{
            $t("el.common.edit")
          }}</el-button>
          <el-button
            v-show="~~scope.row.reportedCourseCount === 0"
            type="text"
            @click="deleteStudent(scope.row.id, scope.row.studentName)"
            >{{ $t("el.common.delete") }}</el-button
          >
        </template>
      </xm-table>
      <el-dialog
        class="add-student-dialog"
        :visible.sync="dialogVisible"
        width="550px"
      >
        <h3>
          {{ editStudentId ? $t("el.common.edit") : $t("el.common.add")
          }}{{ $t("el.infrastructure.student") }}
        </h3>
        <p v-show="showName && editStudentId === null" class="s-tip">
          {{ $t("el.infrastructure.withinSystem")
          }}<span class="s-exchange" @click="removeValidate(false)">{{
            $t("el.infrastructure.throughToAdd")
          }}</span>
        </p>
        <p v-show="!showName && editStudentId === null" class="s-tip">
          {{ $t("el.infrastructure.notStudentID")
          }}<span class="s-exchange" @click="removeValidate(true)">{{
            $t("el.infrastructure.createStudent")
          }}</span>
        </p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="stuRuleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            v-show="!showName"
            :label="$t('el.courseClassManage.studentID')"
            prop="studentCode"
          >
            <el-input
              type="number"
              :placeholder="$t('el.common.EnterStudentID')"
              v-model="ruleForm.studentCode"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="editStudentId !== null"
            :label="$t('el.courseClassManage.studentID')"
            prop="studentCode"
          >
            <span>{{ ruleForm.studentCode }}</span>
          </el-form-item>
          <el-form-item
            class="short-margin"
            v-show="!showName"
            :label="$t('el.courseClassManage.studentName')"
            prop="idStudentName"
          >
            <span>{{
              ruleForm.idStudentName || $t("el.infrastructure.accordingIDMatch")
            }}</span>
          </el-form-item>
          <el-form-item
            class="short-margin"
            v-show="!showName"
            :label="$t('el.courseClassManage.studentNumber')"
            prop="idStudentNumber"
          >
            <span>{{
              ruleForm.idStudentNumber ||
              $t("el.infrastructure.accordingIDMatch")
            }}</span>
          </el-form-item>
          <el-form-item
            v-show="showName || editStudentId !== null"
            :label="$t('el.courseClassManage.studentName')"
            prop="studentName"
          >
            <el-input
              :maxlength="20"
              :placeholder="$t('el.studentManage.enterStudentName')"
              v-model="ruleForm.studentName"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-show="showName || editStudentId !== null"
            :label="$t('el.courseClassManage.studentNumber')"
            prop="studentNumber"
          >
            <el-input
              :placeholder="$t('el.infrastructure.enterStudentID')"
              v-model="ruleForm.studentNumber"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('el.planCourse.school')" prop="schoolCode">
            <el-select
              @change="changeSchool"
              v-model="ruleForm.schoolCode"
              :placeholder="$t('el.planCourse.selectSchool')"
              :disabled="true"
            >
              <el-option
                v-for="item in schoolData"
                :key="item.schoolCode"
                :label="item.schoolName"
                :value="item.schoolCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('el.infrastructure.gradeName')"
            prop="gradeId"
          >
            <el-select
              @change="changeGrade"
              v-loading="loadingGrade"
              @focus="getGradeOptions(ruleForm.schoolCode)"
              v-model="ruleForm.gradeId"
              :disabled="true"
              :placeholder="$t('el.infrastructure.enterGradeName')"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.id"
                :label="item.gradeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('el.planCourse.class')" prop="classId">
            <el-select
              v-loading="loadingClass"
              @focus="getClassOptions(ruleForm.gradeId)"
              v-model="ruleForm.classId"
              :placeholder="$t('el.courseClassManage.classRules')"
              :disabled="true"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.id"
                :label="item.className"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('el.planCourse.state')" prop="studentStatus">
            <el-select
              v-model="ruleForm.studentStatus"
              :placeholder="$t('el.planCourse.SelectState')"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            $t("el.common.cancel")
          }}</el-button>
          <el-button type="primary" @click="confirmDialog">{{
            $t("el.common.confirm")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/core/auth";
export default {
  name: "classStudentManage",
  // 注册组件
  components: {},
  // 定义数据
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      schoolData: [],
      defaultSchoolCode: "",
      defaultSchoolIndex: null,

      tableHeight: undefined,
      loadingPage: true,
      query: {
        schoolCode: "",
        gradeId: "",
        classId: "",
      },
      myHeaders: { Authorization: getToken() },
      // 编辑/新增学生
      dialogVisible: false,
      showName: true,
      ruleForm: {
        studentName: "",
        idStudentName: "",
        studentCode: "",
        studentNumber: "",
        idStudentNumber: "",
        schoolName: "",
        schoolCode: "",
        gradeId: "",
        className: "",
        classId: "",
        studentStatus: 1,
      },
      // 年级列表
      gradeOptions: [],
      // 班级列表
      classOptions: [],
      statusOptions: [
        {
          value: 1,
          label: "在班",
        },
        {
          value: 2,
          label: "转校",
        },
        {
          value: 3,
          label: "退学",
        },
        {
          value: 4,
          label: "休学",
        },
        {
          value: 5,
          label: "毕业",
        },
      ],
      loadingGrade: false,
      loadingClass: false,
      // 添加/编辑
      editStudentId: null,
      uploadLoading: false,
    };
  },
  // 计算属性
  computed: {
    columns() {
      let self = this;
      return [
        {
          label: this.$t("el.common.Number"),
          prop: "studentCode",
          width: "100px",
        },
        {
          label: this.$t("el.courseClassManage.studentName"),
          prop: "studentName",
          width: "85px",
        },
        {
          label: this.$t("el.courseClassManage.studentNumber"),
          prop: "studentNumber",
          width: "100px",
        },
        {
          label: this.$t("el.planCourse.school"),
          prop: "schoolName",
          width: "100px",
        },
        {
          label: this.$t("el.schoolResourceManage.grade"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.planCourse.class"),
          prop: "className",
        },
        {
          label: this.$t("el.infrastructure.creationTime"),
          prop: "createTime",
          width: "100px",
        },
        {
          label: this.$t("el.planCourse.state"),
          prop: "studentStatus",
          formatter(row, column, value) {
            let formateVal;
            switch (Number(row.studentStatus)) {
              case 1:
                formateVal = self.$t("el.infrastructure.inClass");
                break;
              case 2:
                formateVal = self.$t("el.infrastructure.Transfer");
                break;
              case 3:
                formateVal = self.$t("el.infrastructure.dropSchool");
                break;
              case 4:
                formateVal = self.$t("el.infrastructure.outSchool");
                break;
              case 5:
                formateVal = self.$t("el.infrastructure.graduation");
                break;
              default:
                formateVal = "";
                break;
            }
            return formateVal;
          },
        },
        {
          label: this.$t("el.infrastructure.numberCourse"),
          slotName: "alredySigned",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: "100px",
          fixed: "right",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "inputSearch",
          prop: "keyword",
          placeholder: this.$t("el.courseClassManage.enterNameIDStudentID"),
          label: "",
          clearable: true,
        },
      ];
    },
    rules() {
      let commonRules = {
        schoolCode: [
          {
            required: true,
            message: this.$t("el.planCourse.selectSchool"),
            trigger: "change",
          },
        ],
        gradeId: [
          {
            required: true,
            message: this.$t("el.dataAnalysis.selectGrade"),
            trigger: "change",
          },
        ],
        classId: [
          { required: true, validator: this.checkGrade, trigger: "change" },
        ],
        studentStatus: [
          {
            required: true,
            message: this.$t("el.planCourse.SelectState"),
            trigger: "change",
          },
        ],
      };
      if (!this.showName) {
        commonRules["idStudentName"] = [
          {
            required: true,
            message: this.$t("el.planCourse.NoStudentMatched"),
            trigger: "blur",
          },
        ];
        commonRules["idStudentNumber"] = [
          {
            required: true,
            message: this.$t("el.planCourse.StudentIDNotMatched"),
            trigger: "blur",
          },
        ];
        commonRules["studentCode"] = [
          {
            required: true,
            validator: this.validateStudentCode,
            trigger: "blur",
          },
        ];
      } else {
        commonRules["studentName"] = [
          {
            required: true,
            message: this.$t("el.studentManage.enterStudentName"),
            trigger: "blur",
          },
          {
            pattern: /^[·a-zA-Z\u4e00-\u9fa5]+$/,
            message: this.$t("el.infrastructure.enterCorrectName"),
            trigger: "blur",
          },
        ];
        commonRules["studentNumber"] = [
          {
            required: true,
            message: this.$t("el.infrastructure.enterCorrectStudentID"),
            trigger: "blur",
          },
          {
            pattern: /^\d{4,16}$/,
            message: this.$t("el.infrastructure.enterCorrectStudentID"),
            trigger: "blur",
          },
        ];
      }
      return commonRules;
    },
  },
  // 侦听器
  watch: {
    /**
     * 编辑/添加学生弹出框关闭
     * ruleForm重置成左边选择的学生/年级/班级
     */
    dialogVisible(val) {
      if (!val) {
        this.showName = true;
        this.editStudentId = null;
        for (let key in this.ruleForm) {
          if (key === "studentStatus") {
            this.ruleForm.studentStatus = 1;
          } else {
            this.ruleForm[key] = "";
          }
        }
        console.log(this.ruleForm);
        console.log(this.query);
        for (let key in this.query) {
          this.ruleForm[key] = this.query[key];
        }
      }
    },
  },
  // 创建完成钩子
  created() {
    this.init();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    /**
     * 校验学生id
     * 首先 符合规则 纯数字 只能为10位
     * 其次调用接口请求该学生id是否有匹配的学生姓名和学号
     * 如果匹配到 填充到表单中
     * 如果没有匹配到 提示语更改为 此学生ID不存在,且学生姓名/学号也修改提示语
     */
    validateStudentCode(rule, value, callback) {
      console.log(rule);
      if (!/^[0-9]{10}$/.test(value)) {
        callback(new Error(this.$t("el.infrastructure.enterCorrectStudentID")));
      } else {
        this.$store
          .dispatch("checkStudentCodeExist", value)
          .then((res) => {
            console.log(88888);
            if (res.success) {
              this.ruleForm.idStudentName = res.data.studentName;
              this.ruleForm.idStudentNumber = res.data.studentNumber;
              callback();
            } else {
              this.ruleForm.idStudentName = "";
              this.ruleForm.idStudentNumber = "";
              callback(new Error(this.$t("el.planCourse.StudentIDExist")));
            }
          })
          .catch(() => {
            this.ruleForm.idStudentName = "";
            this.ruleForm.idStudentNumber = "";
            callback(new Error(this.$t("el.planCourse.StudentIDExist")));
          });
      }
    },
    // 初始化
    async init() {
      this.query = {
        classId: ~~this.$route.query.classId,
        schoolCode: ~~this.$route.query.schoolCode,
        gradeId: ~~this.$route.query.gradeId,
      };
      await this.getAuthSchoolList();
      this.$nextTick(() => {
        this.$refs["xmTable"] && this.$refs["xmTable"].query();
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    // 导入学生列表excel
    beforeUpload(file) {
      this.uploadLoading = true;
      this.loadingPage = true;
    },
    // 获取学校列表
    async getAuthSchoolList() {
      await this.$store.dispatch("getManageSchoolList").then((res) => {
        this.schoolData = res.data;
        this.schoolData.forEach((item, index) => {
          this.$set(item, "key", `school${index}`);
          this.$set(item, "name", item.schoolName);
          this.$set(item, "leaf", !item.hasGrade);
        });
        if (this.schoolData.length > 0) {
          let schoolCode;
          this.schoolData.forEach((item, index) => {
            if (item.defaultSchool === 1) {
              schoolCode = item.schoolCode;
              this.defaultSchoolIndex = index;
            }
          });
          if (schoolCode === undefined) {
            this.defaultSchoolCode = this.schoolData[0].schoolCode;
            this.defaultSchoolIndex = 0;
          } else {
            this.defaultSchoolCode = schoolCode;
          }
        } else {
          this.defaultSchoolCode = "";
        }
        this.query.schoolCode = this.defaultSchoolCode;
        this.loadingPage = false;
      });
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      let data = {
        schoolCode: this.query.schoolCode,
        gradeId: this.query.gradeId,
        classId: this.query.classId,
        keyword: params.keyword,
        offset: params.offset,
        limit: params.limit,
      };

      data = this.handparams(data);

      return this.$store.dispatch("toGetStudentList", data).then((result) => {
        console.log("result", result);
        return result;
      });
    },
    /**
     * 编辑学生
     * 获取学校/年级/班级列表数据
     * 打开dialog
     */
    async updateAttendClass(item) {
      this.loadingPage = true;
      console.log(item);
      for (let key in item) {
        for (let rkey in this.ruleForm) {
          if (key === rkey) {
            this.ruleForm[rkey] = item[key];
          }
        }
      }
      if (this.ruleForm.gradeId !== "") {
        await this.getGradeOptions(this.ruleForm.schoolCode);
      }
      if (this.ruleForm.classId !== "") {
        await this.getClassOptions(this.ruleForm.gradeId);
      }
      if (this.$refs.stuRuleForm) {
        this.$nextTick(() => {
          this.$refs.stuRuleForm.clearValidate();
        });
      }
      this.editStudentId = item.id;
      this.loadingPage = false;
      this.dialogVisible = true;
    },
    // 删除学生
    deleteStudent(id, studentName) {
      this.$confirm(
        this.$t("el.planCourse.confirmDelete") + `${studentName}?`,
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.confirm"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("deleteStudentData", id).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.$refs["xmTable"].query();
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },
    // 重新加载学校的组织架构数据
    async reloadList() {
      this.query.schoolCode = this.query.gradeId = this.query.classId = "";
      this.loadingPage = true;
      this.schoolData = [];
      this.defaultSchoolCode = "";
      await this.init();
    },
    /**
     * 左侧菜单切换‘学校’
     * 或者
     * 学校下拉框切换学校时
     * 重新请求年级下拉框数据
     */
    async getGradeOptions(schoolCode) {
      this.loadingGrade = true;
      await this.$store
        .dispatch("getGradeData", schoolCode)
        .then((res) => {
          if (res.success) {
            this.gradeOptions = res.data;
          }
          this.loadingGrade = false;
        })
        .catch(() => {
          this.loadingGrade = false;
        });
    },
    /**
     * 左侧菜单切换‘年级’
     * 或者
     * 年级下拉框切换年级时
     * 重新请求班级下拉框数据
     */
    async getClassOptions(gradeId) {
      if (gradeId !== "") {
        this.loadingClass = true;
        await this.$store
          .dispatch("getClassData", gradeId)
          .then((res) => {
            if (res.success) {
              this.classOptions = res.data;
            }
            this.loadingClass = false;
          })
          .catch(() => {
            this.loadingClass = false;
          });
      } else {
        this.$notify.error({
          message: this.$t("el.infrastructure.choosefirstGrade"),
        });
      }
    },
    // 更改学校的时候重置
    changeSchool() {
      this.gradeOptions = this.classOptions = [];
      this.ruleForm.gradeId = this.ruleForm.classId = "";
    },
    // 更改年级的时候重置
    changeGrade() {
      this.classOptions = [];
      this.ruleForm.classId = "";
    },
    /**
     * 当左侧菜单点击‘学校’时
     * 请求当前学校下的所有年级接口
     * 此时点击‘添加学生’按钮
     * 默认赋值的年级下拉框数据为该数据
     * 不需要二次请求
     */
    getGradeData(data) {
      this.gradeOptions = data;
    },
    /**
     * 当左侧菜单点击‘年级’时
     * 请求当前学校下的所有班级接口
     * 此时点击‘添加学生’按钮
     * 默认赋值的班级下拉框数据为该数据
     * 不需要二次请求
     */
    getClassData(data) {
      this.classOptions = data;
    },
    // 点击添加学生按钮
    async openStudentDialog() {
      this.ruleForm.gradeId = this.ruleForm.classId = "";
      this.gradeOptions = this.classOptions = [];
      for (var key in this.query) {
        this.ruleForm[key] = this.query[key];
      }
      console.log(this.ruleForm);
      this.editStudentId = null;
      this.dialogVisible = true;
      if (this.ruleForm.gradeId !== "") {
        await this.getGradeOptions(this.ruleForm.schoolCode);
      }
      if (this.ruleForm.classId !== "") {
        await this.getClassOptions(this.ruleForm.gradeId);
      }
      this.$nextTick(() => {
        this.$refs.stuRuleForm.clearValidate();
      });
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    // 如果 已报课程>0, 跳转已报课程页面
    toReported(item) {
      let obj = {
        id: item.id,
        studentCode: item.studentCode,
        schoolName: item.schoolName,
        gradeName: item.gradeName,
        className: item.className,
        studentName: item.studentName,
      };
      this.$router.push({
        name: "classStudentReported",
        query: {
          obj: JSON.stringify(obj),
        },
      });
    },
    // 新增学生 确定
    addStudent() {
      // 如果是通过输入学生姓名/学号方式 提交表单
      // 重置一些参数
      let params = this.handparams(this.ruleForm);
      if (this.showName) {
        params.studentCode = params.idStudentName = params.idStudentNumber = "";
      } else {
        params.studentName = params.idStudentName;
        params.studentNumber = params.idStudentNumber;
      }
      if (params.idStudentName) {
        delete params.idStudentName;
        delete params.idStudentNumber;
      }
      params = this.handparams(params);
      this.$store.dispatch("addSchoolStudentData", params).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: response.message,
          });
          this.dialogVisible = false;
          this.$refs["xmTable"].query();
        } else {
          this.$message({
            type: "error",
            message: response.message,
          });
        }
      });
    },
    // 编辑学生 确定
    editStudent() {
      let params = this.handparams(this.ruleForm);
      console.log(params);
      let data = {
        studentId: this.editStudentId,
        form: params,
      };
      this.$store.dispatch("updateSchoolStudentData", data).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: response.message,
          });
          this.dialogVisible = false;
          this.$refs["xmTable"].query();
        } else {
          this.$message({
            type: "error",
            message: response.message,
          });
        }
      });
    },
    /**
     * 添加/编辑学生
     * editStudentId不为null 编辑
     * editStudentId: null 添加
     */
    confirmDialog() {
      console.log(this.ruleForm);
      this.$refs.stuRuleForm.validate((valid) => {
        if (valid) {
          if (this.editStudentId === null) {
            this.addStudent();
          } else {
            this.editStudent();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 导入成功，重新加载数据
    async handleSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: response.message,
        });
        await this.$refs["xmTable"].query();
      } else {
        this.$message({
          type: "error",
          message: response.message,
        });
      }
      this.uploadLoading = false;
      this.loadingPage = false;
    },
    // 切换表单展示，重置检验
    removeValidate(boolean) {
      this.showName = boolean;
      this.$nextTick(() => {
        this.$refs.stuRuleForm.clearValidate();
      });
    },
    /**
     * 输入学生id
     * 失焦事件
     */
    checkScode() {
      if (/^[0-9]{10}$/.test(this.ruleForm.studentCode)) {
        this.$store
          .dispatch("checkStudentCodeExist", this.ruleForm.studentCode)
          .then((res) => {
            if (!res.success) {
              this.ruleForm.idStudentName = "";
              this.ruleForm.idStudentNumber = "";
            } else {
              this.ruleForm.idStudentName = res.data.studentName;
              this.ruleForm.idStudentNumber = res.data.studentNumber;
            }
          })
          .catch(() => {
            this.ruleForm.idStudentName = "";
            this.ruleForm.idStudentNumber = "";
          });
      } else {
        this.ruleForm.studentName = "";
        this.ruleForm.studentNumber = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.class-student {
  // display: flex;
  background: #fff;
  padding: 22px 15px 22px 9px;
  border-radius: 4px;
  min-height: 565px;
  box-sizing: border-box;

  &-top {
    height: 32px;
    line-height: 32px;
    margin-bottom: 20px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
    &-text {
      font-size: 14px;
      // font-family: MicrosoftYaHei;
      color: @themeBlue;
    }
  }

  .u-upload-loading {
    position: absolute;
    color: #999;
    width: calc(100% - 40px);
    top: 55%;
    text-align: center;
    z-index: 99999;
  }

  .s-span {
    color: #999;
  }
  .sclick {
    color: #6049ff;
    cursor: pointer;
  }

  .el-button--primary {
    font-size: 13px;
    color: #f9f9f9;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #6049ff;
    border-radius: 5px;
    float: left;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    margin-right: 8px;
    a {
      color: #6049ff;
    }
  }
  /deep/ .el-upload-list {
    display: none;
  }
  .right-list {
    width: 100%;
    overflow-x: auto;
    margin-left: 20px;

    .custom-button {
      display: inline-block;
      text-decoration: none;
    }
    /deep/ .el-form--inline {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: flex-end;
      // padding-left: 50px;
      position: relative;
      .el-form-item:nth-child(1) {
        position: absolute;
        right: -10px;
      }
      .el-form-item:nth-child(2) {
        .el-form-item__content {
          display: flex;
        }
      }
      .el-form-item {
        margin-bottom: 0;
      }
    }
    /deep/ .is-plain {
      background: #fff;
      border-color: #6049ff;
      color: #6049ff;
    }
    /deep/ .el-input--suffix {
      width: 250px;
      .el-icon-search {
        cursor: pointer;
      }
    }
    /deep/ .xm-table {
      padding: 0;
      // margin-left: 50px;
    }

    .add-student-dialog {
      /deep/ .el-input {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          margin: 0;
        }
        .el-input__inner {
          line-height: 1px !important;
        }
      }
      h3 {
        text-align: start;
      }
      .mock-tips {
        text-align: start;
        padding-left: 130px;
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        margin: 0;
        margin-top: -8px;
      }
      .s-tip {
        margin-top: 20px;
        margin-bottom: 20px;
        color: #999;
        text-align: start;
      }
      .s-exchange {
        color: #6049ff;
        cursor: pointer;
      }
      .el-select {
        width: 100%;
      }
      /deep/ .el-input--suffix {
        width: 100%;
      }
      /deep/ .el-dialog__body {
        padding: 0 30px;
      }
      /deep/ .el-form-item__content {
        text-align: start;
      }
      /deep/ .el-form-item {
        margin-bottom: 15px;
      }
      /deep/ .short-margin {
        .el-form-item__label,
        .el-form-item__content {
          line-height: 30px;
        }
      }
      /deep/ .el-form-item__error {
        top: 95%;
      }
      .dialog-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
